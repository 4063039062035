import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-list-content',
  template: '',
  styleUrls: ['./list-content.component.scss'],
})
export class ListContentComponent implements OnInit, OnDestroy {

  listContent: any[];
  listParamValidator = {};
  currentParam = {};
  isInfiniteList: boolean;
  isListEmpty: boolean;

  firstCallDone: boolean;

  // List display variables
  totalElements: number;
  numberOfElements: number;
  page = 1;
  size = 5;
  sort = '';
  sortDirection = '';

  // Search variables
  isSearchActive: boolean;

  isAdvancedSearchDisplayed: boolean;
  advancedSearchForm: FormGroup;

  private $basicSearchSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  basicSearch: string = '';

  // Other variables
  subscriptions: Subscription[] = [];

  selectedIds = [];
  isMultipleChecked: boolean;

  constructor(public router: Router, public route: ActivatedRoute) {}

  /**
   * This method is fired when the component is initialized.
   * It initialize boolean state variable and call to subscribeToBasicSearch.
   */
  ngOnInit(): void {
    this.isInfiniteList = false;
    this.isAdvancedSearchDisplayed = false;
    this.isSearchActive = false;
    this.isListEmpty = true;
    this.firstCallDone = false;

    this.subscribeToBasicSearch();
  }

  resetMultipleSelection() {
    this.selectedIds = [];
    this.isMultipleChecked = false;
    const checkbox = document.getElementById('select-all') as HTMLInputElement;
    if (checkbox != null) {checkbox.checked = false; }
    const toggle = document.getElementById('toggle') as HTMLInputElement;
    if (toggle != null) {toggle.checked = false; }
  }

  /**
   * Subscribe to queryParamMap observable of the Angular Router.
   * At every change in the url's query params the method cleanListParam is called.
   * If the query params are valid the list content is retrieved and the list display variables are updated.
   * If the query params are not valid the url's query params are updated with the params cleaned.
   * This method should be called in the initialization of the children component.
   */
  subscribeToQueryParam(): void {
    this.subscriptions.push(this.route.queryParamMap.subscribe((param: any) => {
        const [isParamsValid, paramsCleaned] = this.cleanListParam(param.params);
        if (isParamsValid) {
          this.updateParamListDisplay(paramsCleaned);
          this.retrieveListContent(paramsCleaned);
          this.currentParam = paramsCleaned;
        } else {
          this.updateQueryParam(paramsCleaned);
        }
      }
    ));
  }

  /**
   * Update the url's query params.
   * @param params : object of params to be updated in the url's query params.
   */
  updateQueryParam(params: object): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  /**
   * Refresh list content by calling retrieveListContent.
   * This method is specially useful in infinite list.
   */
  refreshListContent(): void {
    this.retrieveListContent(this.currentParam);
  }

  /**
   * Clean params argument trough listParamValidator variable.
   * Iterate over every value in params object.
   * If the param is not included in listParamValidator the param is set to null and the variable isParamsValid is set to false.
   * If the param does not pass the test specified in listParamValidator the param is set to null and the variable isParamsValid is set to false.
   * Four tests can be specified in listParamValidator : regex, date range, boolean and array.
   * Return isParamsValid and paramsCleaned.
   * @param params : object of query params to be cleaned.
   */
  private cleanListParam(params: any): [boolean, object] {
    const paramsCleaned = {};
    let isParamsValid = true;

    Object.keys(params).forEach(param => {
      if (this.listParamValidator.hasOwnProperty(param)) {
        if (this.listParamValidator[param] instanceof RegExp) {
          if (this.listParamValidator[param].test(params[param])) {
            paramsCleaned[param] = params[param];
          } else {
            paramsCleaned[param] = null;
            isParamsValid = false;
          }
        } else if (typeof this.listParamValidator[param] === 'string' && this.listParamValidator[param] === 'rangeDate') {
          // Date range must be in the following format : YYYY-MM-DD,YYYY-MM-DD.
          if (!RegExp('^[0-9]{4}(-[0-9]{2}){2}\,[0-9]{4}(-[0-9]{2}){2}$').test(params[param])) {
            paramsCleaned[param] = null;
            isParamsValid = false;
          } else if (params[param].split(',').some(date => !moment(date, 'YYYY-MM-DD', true).isValid())) {
            paramsCleaned[param] = null;
            isParamsValid = false;
          } else {
            paramsCleaned[param] = params[param];
          }
        } else if (typeof this.listParamValidator[param] === 'string' && this.listParamValidator[param] === 'boolean') {
          // A boolean param can only be true. If the param is false it will be set to null and removed from the url's query param.
          if (params[param] === 'true') {
            paramsCleaned[param] = true;
          } else {
            paramsCleaned[param] = null;
            isParamsValid = false;
          }
        } else if (Array.isArray(this.listParamValidator[param])) {
          if (this.listParamValidator[param].includes(params[param])) {
            paramsCleaned[param] = params[param];
          } else {
            paramsCleaned[param] = null;
            isParamsValid = false;
          }
        } else if (typeof this.listParamValidator[param] === 'string' && this.listParamValidator[param] === 'date') {
          if (!RegExp('^[0-9]{4}(-[0-9]{2}){2}$').test(params[param]) && !moment(params[param], 'DD-MM-YYYY', true).isValid()) {
            paramsCleaned[param] = null;
            isParamsValid = false;
          } else {
            paramsCleaned[param] = params[param];
          }
        }
      } else {
        paramsCleaned[param] = null;
        isParamsValid = false;
      }
    });

    return [isParamsValid, paramsCleaned];
  }

  /**
   * Update list display variable with params argument.
   * If the params contain the search key, isSearchActive is set to true and isAdvancedSearchDisplayed is set to false.
   * If an advanced search form is initialized in the children component the method mapQueryParamToAdvancedSearchForm is called.
   * @param params : object of query params.
   */
  private updateParamListDisplay(params: any): void {
    if (params.size) {
      this.size = parseInt(params.size, 10);
    }

    if (params.page) {
      this.page = parseInt(params.page, 10);
    }

    if (params.sort) {
      this.sort = params.sort.split(',')[0];
      this.sortDirection = params.sort.split(',')[1];
    }

    if (params.search) {
      this.isAdvancedSearchDisplayed = false;
      this.isSearchActive = true;
      this.basicSearch = params.search;
    } else if (this.advancedSearchForm) {
      this.mapQueryParamToAdvancedSearchForm(params);
    }
  }

  /**
   * Map every query param to the corresponding control in the advanced search form.
   * This method can be override for a more specific behavior.
   * @param params : object of query params.
   */
  mapQueryParamToAdvancedSearchForm(params: any): void {
    Object.keys(params).forEach(paramKey => {
      if (this.advancedSearchForm.contains(paramKey)) {
        this.isAdvancedSearchDisplayed = true;
        this.isSearchActive = true;
        this.advancedSearchForm.get(paramKey).setValue(params[paramKey]);
      }
    });
  }

  /**
   * Map the advanced search form value to the query param.
   * This method can be override for a more specific behavior.
   * @param advancedSearchForm : advanced search form value.
   */
  mapAdvancedSearchFormToQueryParam(advancedSearchForm: any): any {
    return advancedSearchForm;
  }

  /**
   * The purpose of this method is to retrieve the list content with a http call.
   * This method must be override in the children component.
   * @param params : object of query params .
   */
  retrieveListContent(params: any): void {}

  /**
   * Subscribe to the basic search observable.
   * The value is read with a delay of 300ms. A null or an unchanged value is ignored.
   */
  private subscribeToBasicSearch(): void {
    this.subscriptions.push(
      this.$basicSearchSubject.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(value => value !== null),
        tap(value => this.basicSearch = value)
      ).subscribe(value => {
        this.handleBasicSearch(value);
      })
    );
  }

  /**
   * Handle basic search.
   * If the search value length is superior or equal to 3 the search is active and the url's query params are updated.
   * If the search value length is inferior to 3 the search active is set to false and the search query param is removed.
   * @param value : search value.
   */
  private handleBasicSearch(value: string): void {
    if (value.length >= 3) {
      this.isSearchActive = true;
      this.updateQueryParam({page: 1, search: value});
    } else {
      if (this.isSearchActive) {
        this.isSearchActive = false;
        this.updateQueryParam({page: 1, search: null});
      }
    }
  }

  /**
   * This method is triggered on every key up on the basic search input field.
   * The value is trim and feed the basic search observable.
   * @param value : basic search input value
   */
  triggerBasicSearch(value: string): void {
    this.$basicSearchSubject.next(value.trim());
  }

  /**
   * Toggle the state between the advanced and basic search.
   * When the advanced search is hidden the advanced search form is reset and the query params updated.
   * When the advanced search is displayed the basic search is triggered with an empty string.
   */
  toggleAdvancedSearch(): void {
    this.isAdvancedSearchDisplayed = !this.isAdvancedSearchDisplayed;

    if (!this.isAdvancedSearchDisplayed) {
      this.advancedSearchForm.reset();
      if (this.isSearchActive) {
        this.isSearchActive = false;
        this.updateQueryParam({page: 1, ...this.advancedSearchForm.value});
      }
    } else {
      this.triggerBasicSearch('');
    }
  }

  /**
   * Submit method of the advanced search form.
   * Update the url's query params with the advanced search form value.
   * If the list is an infinite list the content is reset at every submit.
   */
  advancedSearchFormSubmit(): void {
    if (this.advancedSearchForm.dirty) {
      this.isSearchActive = true;
      if (this.isInfiniteList) {
        this.page = 0;
        this.updateQueryParam({...this.mapAdvancedSearchFormToQueryParam(this.advancedSearchForm.value)});
      } else {
        this.updateQueryParam({page: 1, ...this.mapAdvancedSearchFormToQueryParam(this.advancedSearchForm.value)});
      }
    }
    this.advancedSearchForm.markAsPristine();
    this.resetMultipleSelection();
  }

  /**
   * Test if the advanced search form value is empty
   */
  isAdvancedSearchFormEmpty(): boolean {
    let isEmpty = true;
    for (const value of Object.values(this.advancedSearchForm.value)) {
      if (value !== null) {
        isEmpty = false;
        break;
      }
    }
    return isEmpty;
  }

  /**
   * Update the url's query params with the size variable.
   * This method is fired each time the size select value is changed.
   */
  changeSize(): void {
    this.updateQueryParam({page: 1, size: this.size});
  }

  /**
   * Update the url's page query param with the given value.
   * @param $event : object with the value of the page and the number of items per page.
   */
  changePage($event: PageChangedEvent): void {
    this.updateQueryParam({page: $event.page});
  }

  /**
   * Update the url's query param with the given sort value.
   * If the given value is equal to the sort variable the sortDirection is switched between 'asc' and 'desc'.
   * @param value : sort value.
   */
  sortColumn(value: string): void {
    if (this.sort !== value) {
      this.sort = value;
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = (this.sortDirection === 'asc') ? 'desc' : 'asc';
    }
    this.updateQueryParam({page: 1, sort: this.sort + ',' + this.sortDirection});
  }

  /**
   * This method is fired when the component is destroyed.
   * It will clean all the subscriptions made in the component.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
