<header class="app-header navbar" [ngClass]="{'devmode': !['prod', 'demo'].includes(environment.env)}">
  <button class="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button" data-toggle="sidebar-show">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand ">
    <img class="navbar-brand-full sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/logo.svg" alt="CoreUI Logo">
    <img class="navbar-brand-minimized sidebar-minimizer brand-minimizer" onclick="collapseNavbar()"
         src="assets/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo">
  </a>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item px-2">
      <ng-select
        [clearable]="false"
        [items]="languages"
        [searchable]="false"
        [formControl]="languageSettingFc"
        [loading]="loaders['languageSetting']"
        class="ng-select-custom"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div [ngClass]="['flag-icon', 'flag-icon-'+ item|lowercase]"></div>
          <span> {{'global.lang.' + item|lowercase|translate}}</span>
        </ng-template>
      </ng-select>
    </li>
    <li class="nav-item badge badge-danger" *ngIf="!['prod', 'demo'].includes(environment.env)">ENV : {{ environment.env }}</li>
    <li class="nav-item dropdown" dropdown>
      <a class="nav-link cursor-pointer" data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false" dropdownToggle>
        <img class="img-avatar" [src]="'https://ui-avatars.com/api/?name='+username">
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <span class="dropdown-item">{{username | titlecase}}</span>
        <a class="dropdown-item" (click)="logout()">
          <span class="fa fa-lock"></span> {{'core.components.header.button.logout'|translate}}</a>
      </div>
    </li>
    <li class="nav-item">
      <button class="nav-link btn-link" (click)="logout()" title="Logout">
        <span class="icon-power"></span>
      </button>
    </li>
  </ul>
</header>
