import { CountryEnum } from '../../shared/enums/Country.enum';
import {TaxGetResource} from './TaxGroupModel';

export class CompanyTaxesPostModel {
  taxGroupId: string;
  numbers: TaxNumber[];
}

export class TaxNumber {
  taxTypeId: string;
  number: string;
}

export class TaxGroupGetModel {
  id: string;
  country: CountryEnum;
  name: String ;
  taxes: Set<TaxGetResource>;
}

export class CompanyTaxesGetModel {
  configId: string;
  taxGroup: TaxGroupGetModel;
  numbers: TaxNumber[];
}
