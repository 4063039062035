import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitySheetsComponent } from './activity-sheets/activity-sheets.component';
import {ActivityReportRoutingModule} from './activity-report-routing.module';
import {SharedModule} from '../shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [ActivitySheetsComponent],
    imports: [
        CommonModule,
        ActivityReportRoutingModule,
        SharedModule,
        FontAwesomeModule
    ]
})
export class ActivityReportModule { }
