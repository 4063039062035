import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AlertService} from '../../core/services/alert.service';
import {AuthService} from '../../core/services/auth-service.service';
import {UserCredentialsModel} from '../models/user-credentials.model';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from '../../core/services/translator.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  isLoggingIn = false;
  protected isModified: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private alertService: AlertService,
              private router: Router,
              private logger: NGXLogger,
              private translatorService: TranslatorService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.maxLength(50)]],
      password: [null, [Validators.required, Validators.maxLength(50)]]
    });
    this.loginForm.valueChanges.subscribe(value => {
      this.isModified = true;
    });

  }

  loginFormSubmit(): void {
    this.isLoggingIn = true;
    this.isModified = false;
    this.alertService.clear();
    const userCredentials = new UserCredentialsModel();
    userCredentials.email = this.loginForm.value.email;
    userCredentials.password = this.loginForm.value.password;
    this.subscriptions.push(this.authService.login(userCredentials).subscribe(
      (res: any) => {
        this.authService.storeToken(res);
        this.router.navigate(['dashboard']);
        this.translatorService.changeLang(this.translatorService.getLanguage());
        this.isLoggingIn = false;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.subscriptions.push(this.translateService.get(['authentication.login-ts.login-form-submit.error.bad_credentials',
          'authentication.login-ts.login-form-submit.error.account_not_activated',
          'authentication.login-ts.login-form-submit.error.account_blocked']).subscribe((data: any) => {
          if (error.error.code === 'UAA_AUTH_UNAU_ERR_2' || error.error.code === 'UAA_RES_NOT_FND_ERR_4') {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.bad_credentials'], 'warning');
          }
          if (error.error.code === 'UAA_AUTH_UNAU_ERR_5' ) {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.account_blocked'], 'warning');
          }
          if (error.error.code === 'UAA_AUTH_FORB_ERR_4') {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.account_not_activated'], 'warning');
          }
        }));
        this.isLoggingIn = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
