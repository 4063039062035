import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject} from 'rxjs';
import {AccountValidationModel} from '../../authentication/models/account-validation.model';
import {UserCredentialsModel} from '../../authentication/models/user-credentials.model';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usernameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private jwtHelper: JwtHelperService, private http_client: HttpClient, private router: Router) {
  }

  decodeToken(key?: string): string {
    const token = this.jwtHelper.decodeToken(localStorage.getItem('access_token'));
    if (key) {
      return token[key];
    } else {
      return token;
    }
  }

  /**
   * This method returns a boolean if the user is connected or not.
   */
  isAuthenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

  /**
   * This method delete the access_token from the localStorage and route the app to the login page
   */
  logout() {
    const token = localStorage.getItem(commonProperties.token);
    localStorage.clear();
    if (token) {
    this.http_client.post(environment.api_root + commonProperties.logout, {token})
      .subscribe(() => {
        this.router.navigate(['/login']);
        }, () => {
          this.router.navigate(['/login']);
        }, () => {
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Logs a user by sending the user credentials to the back office API to connect him
   * @param userCredentials request body holding the username, password and the app type
   */
  login(userCredentials: UserCredentialsModel) {
    // console.log('your user inputs:', userCredentials);
    return this.http_client.post(environment.api_root + commonProperties.login, userCredentials);

  }

  /**
   * this method store the access_token in localstorage and route the app to the dashboard page
   * @param res response from the API
   */
  storeToken(res: any) {
    localStorage.setItem(commonProperties.token, res.access_token);
    localStorage.setItem(commonProperties.token_expiration, res.expires_in);
    this.setUsername(this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token))['name']);
  }

  /**
   * Request a new password for the account related to the email specified
   * @param email user's email
   */
  forgetPassword(email: string) {
    let params = new HttpParams().set('email', email);
    return this.http_client.get(environment.api_root + commonProperties.forgetPassword, {params});
  }

  /**
   * Validate an account to make it active by specifying the token received by email and the password account
   * @param token activation token
   * @param password password account
   */
  validateAccount(token: string, password: string) {
    let params = new HttpParams().set('activationKey', token);
    return this.http_client.post(environment.api_root + commonProperties.activateAccount, new AccountValidationModel(password), {params: params})
  }

  /**
   * Reset a password by specifying the token received by email and the new password
   * @param token reset password token
   * @param password new password to set for the account
   */
  resetPassword(token: string, password: string) {
    const params = new HttpParams().set('resetKey', token);
    return this.http_client.post(environment.api_root + commonProperties.resetPassword, new AccountValidationModel(password), {params: params});
  }

  /**
   *
   */
  getRole() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token))['authorities'];
    }
  }

  getEmployeeAccount(employeeId: string) {
    const params = new HttpParams().set('employeeId', employeeId);
    return this.http_client.get(environment.api_root + commonProperties.accountByEmployeeId, {params: params});
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
  }

  getUsername() {
    if (this.isAuthenticated() && !this.usernameSubject.value) {
      this.setUsername(this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token))['name']);
    }
    return this.usernameSubject.asObservable();
  }

  isRoot() {
    return this.getRole() === 'ROLE_ROOT';
  }

  updateUserLang(body: any) {
    return this.http_client.patch(environment.api_root + commonProperties.lang.replace(':employeeId', this.decodeToken('userId')), body);
  }

  getUserLang() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).lang;
    } else {
      return null;
    }
  }

  getID() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token))['userId'];
    }
  }

  refreshToken() {
    const token = localStorage.getItem(commonProperties.token);
    const refreshTokenBody = {
      token
    };
    return this.http_client.post(environment.api_root + commonProperties.refreshToken, refreshTokenBody);
  }

  get isSaAdmin(): boolean {
    return this.getRole() === 'ROLE_SA_ADMIN';
  }
}
