import {animate, group, keyframes, query, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../assets/environments/environment';
import {fadeInAnimation} from '../../../shared/animation/common.animation';
import {EventBusService} from '../../../shared/utils/event-bus.service';
import {AuthService} from '../../services/auth-service.service';
import { TranslatorService } from '../../services/translator.service';
import {FormControl} from '@angular/forms';
import {concatAll, from, Subscription, toArray} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('newNotification', [
      transition(':enter', [
        group([
          useAnimation(fadeInAnimation),
          query('i.fa', [
            animate('.4s .1s', keyframes([
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(0)'})
            ]))
          ], {optional: true})
        ])
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  username: string;
  protected readonly environment = environment;
  languages: string[];
  languageSettingFc: FormControl = new FormControl(null);
  subscriptions: Subscription[] = [];
  loaders: { [key: string]: boolean } = {};

  constructor(
    private authService: AuthService,
    private eventBusService: EventBusService,
    private translatorService: TranslatorService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
  ) {
    this.languages = this.translatorService.languages;
    this.languageSettingFc.setValue(this.authService.getUserLang());

    this.subscriptions.push(this.languageSettingFc.valueChanges.subscribe(lang => {
      this.loaders['languageSetting'] = true;
      const services = from([this.authService.updateUserLang({lang}), this.authService.refreshToken()]).pipe(concatAll(), toArray());

      services.subscribe({
        next: (res: any[]) => {
          this.translatorService.changeLang(res[0].lang);
          this.authService.storeToken(res[1]);
          this.loaders['languageSetting'] = false;
        },
        error: () => {
          this.toastrService.error(this.translateService.instant('global.ts.error'));
          this.loaders['languageSetting'] = false;
        }
      });
    }));
  }

  ngOnInit(): void {
    this.authService.getUsername().subscribe({
      next: value => {
        if (value) {this.username = value; }
      }
    });
  }

  logout(): void {
    this.eventBusService.clearEvents();
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
